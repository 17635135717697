import React, { useRef } from 'react'
import Head from 'next/head'
import Link from 'next/link'
import classNames from 'classnames/bind'
import { GetServerSidePropsContext } from 'next'
import { useQuery } from '@apollo/client'

import { initializeApollo, addApolloState } from 'lib/apolloClient'
import { ICatalog } from 'types/Catalog'
import runMiddleware from 'lib/middleware'
import { useUserSession, getSessionAccountId } from 'hooks/useUserSession'
import { withProductCategoryPreferencesPage } from 'hoc'

/* GraphQL */
import GET_SUPER_DEPARTMENTS from 'modules/marketplace/header/components/DepartmentsMenuBar/graphql/GetSuperDepartments.graphql'
import GET_HOMEPAGE_DATA from './graphql/GetHomepageData.graphql'
import {
  GetHomepageData,
  GetHomepageData_departmentFullList,
  GetHomepageData_currentPromotions_homepage_departments_nodes_catalogs_nodes,
  GetHomepageData_currentPromotions_slots_nodes
} from './graphql/__generated__/GetHomepageData'

/* Components */
import { Robots, StructuredData } from 'components/SEO'
import { HomepageHeroMarketingPanel, SellerMarketingPanel } from 'modules/website/components/MarketingPanels'
import DepartmentsMenuBar from 'modules/marketplace/header/components/DepartmentsMenuBar'
import RepHowToGuideModal from 'modules/signup/components/RepHowToGuideModal'
import WelcomeRepBanner from 'modules/rep-hub/components/WelcomeRepBanner'
import AddTradeReferencesBanner from 'modules/rep-hub/components/AddTradeReferencesBanner'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import MarketingRetailerMapPanel from 'modules/website/components/MarketingRetailerMapPanel'
import MarketingSellerTestimonialsPanel from 'modules/website/components/MarketingSellerTestimonialsPanel'
import MarketingStatsPanel from 'modules/website/components/MarketingStatsPanel'
import BuyerFlowManager from 'modules/signup/components/BuyerFlowManager/BuyerFlowManager'
import UnfinishedOrderBanner from 'modules/buyer-hub/ordering/components/UnfinishedOrderBanner'
import MarketplaceRecommendationsPanel from 'modules/recommendations/components/MarketplaceRecommendationsPanel'

/* Page Components */
import BasePage from 'modules/marketplace/layout/components/BasePage'
import CatalogListHorizontal from 'modules/marketplace/catalog/components/CatalogListHorizontal'
import PromotionBanner from 'modules/marketplace/catalog/components/PromotionBanner'
import SubDepartmentCards from 'modules/marketplace/catalog/components/SubDepartmentCards'
import YourCatalogList from 'modules/marketplace/catalog/components/YourCatalogList'

/* Page Styles */
import styles from './HomePage.module.css'
const cx = classNames.bind(styles)

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  runMiddleware(ctx)

  const sessionAccountId = getSessionAccountId()

  // Don't bother fetching any data server side if there is an existing session.
  //
  // It feels much faster to just let the client fetch data and render the page
  if (sessionAccountId) {
    return { props: {} }
  }

  const apolloClient = initializeApollo()

  await apolloClient.query({ query: GET_SUPER_DEPARTMENTS }) // Preload main departments menu
  await apolloClient.query({ query: GET_HOMEPAGE_DATA })

  return addApolloState(apolloClient, { props: {} })
}

type SuperDepartment = {
  department: GetHomepageData_departmentFullList
  catalogs?: GetHomepageData_currentPromotions_homepage_departments_nodes_catalogs_nodes[]
  promotionSlot?: GetHomepageData_currentPromotions_slots_nodes
}

const SuperDepartmentSection = ({
  isTinted = false,
  superDepartment
}: {
  isTinted?: boolean
  superDepartment?: SuperDepartment
}) => (
  <section className={cx(styles.superDepartmentSection, { isTinted })}>
    <Container>
      {superDepartment && (
        <>
          <CatalogListHorizontal
            className={styles.catalogListHorizontal}
            key={superDepartment.department?.slug}
            catalogs={superDepartment.catalogs as unknown as ICatalog[]}
            title={`Shop ${superDepartment.department?.name}`}
            superDepartment={superDepartment.department}
            promotionSlot={superDepartment.promotionSlot}
          />
          <SubDepartmentCards
            className={styles.subDepartmentCards}
            superDepartmentSlug={superDepartment.department?.slug || ''}
          />
        </>
      )}
    </Container>
  </section>
)

const SuperDepartmentCard = ({ name, slug, moreText }: { name: string; slug: string; moreText: string }) => (
  <Link href={`/shop/d/${slug}`}>
    <a className={styles.superDepartmentCard}>
      <img
        className={styles.superDepartmentCardImage}
        src={`/marketplace/superdepartment-covers/${slug}.jpg`}
        alt={`Shop ${name} wholesale`}
      />
      <div className={styles.superDepartmentCardInfo}>
        <h3 className={styles.superDepartmentCardTitle}>{name}</h3>
        <p className={styles.superDepartmentCardDescription}>{moreText}</p>
      </div>
    </a>
  </Link>
)

const HomePage = () => {
  const { isRep, isGuest, isBuyer } = useUserSession()
  const buyerRecommendationsRef = useRef(null)
  const repRecommendationsRef = useRef(null)

  const homepageDataQuery = useQuery<GetHomepageData>(GET_HOMEPAGE_DATA)

  const superDepartments = homepageDataQuery.data?.departmentFullList?.map(superDepartment => {
    return {
      department: superDepartment,
      catalogs: homepageDataQuery?.data?.currentPromotions?.homepage.departments?.nodes.find(
        d => d.id === superDepartment.id
      )?.catalogs?.nodes,
      promotionSlot: homepageDataQuery?.data?.currentPromotions?.slots?.nodes.find(
        p => p.promotion?.department?.id === superDepartment.id
      )
    }
  })

  const promotions = homepageDataQuery?.data?.currentPromotions?.slots.nodes
  const homepageBanner = promotions?.find(p => p?.promotion.type === 'HOMEPAGE_BANNER')

  return (
    <BasePage hasMarketingFooter={isGuest} hasGuestBanner={isGuest} hideIntercom>
      <Head>
        <title>Fieldfolio · Australia’s No. 1 Wholesale Marketplace</title>
        <meta
          name="description"
          content="Fieldfolio is Australia’s Leading Wholesale Marketplace. With Over 200,000 Products From 285 Australian Wholesalers Available to Order Online"
        />
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}`} />
        <Robots content="follow, index" />
      </Head>

      <StructuredData
        data={{
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}`,
          potentialAction: {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/search?q={search_term_string}`
            },
            'query-input': 'required name=search_term_string'
          }
        }}
      />

      <DepartmentsMenuBar />

      {/* Buyer banners */}
      {isBuyer && <UnfinishedOrderBanner />}

      {/* Rep banners */}
      {isRep && (
        <div className={styles.repBannersContainer}>
          <WelcomeRepBanner />
          <AddTradeReferencesBanner />
        </div>
      )}

      {homepageBanner && <PromotionBanner promotionSlot={homepageBanner} isHomepageBanner />}

      {isGuest && (
        <>
          <HomepageHeroMarketingPanel />
          <MarketingStatsPanel />
        </>
      )}

      {!isGuest && (
        <Container>
          <YourCatalogList />
        </Container>
      )}

      <Container>
        <div ref={buyerRecommendationsRef}>
          {/* Show only after all of the queries have been fetched */}
          {isBuyer && !homepageDataQuery.loading && homepageDataQuery.data && (
            <MarketplaceRecommendationsPanel className={styles.buyerRecommendationsPanel} hideLoading />
          )}
        </div>
      </Container>

      <Container className={styles.superDepartmentCards}>
        <SuperDepartmentCard
          name="Shop Fashion"
          slug="fashion"
          moreText="Activewear, Maternity, Women’s Apparel, Men’s Apparel and more..."
        />
        <SuperDepartmentCard name="Shop Home" slug="home" moreText="Art, Candles, Collectables and more..." />
      </Container>

      <SuperDepartmentSection
        isTinted
        superDepartment={superDepartments?.find(d => d?.department.slug === 'fashion')}
      />

      <Container className={styles.superDepartmentCards}>
        <SuperDepartmentCard
          name="Shop Kitchen"
          slug="kitchen"
          moreText="Glassware, Gourmet Food, Utensils and more..."
        />
        <SuperDepartmentCard
          name="Shop Outdoors"
          slug="outdoors"
          moreText="Adventure, Garden, Outdoor, Travel and more..."
        />
      </Container>

      <SuperDepartmentSection isTinted superDepartment={superDepartments?.find(d => d?.department.slug === 'gifts')} />

      <Container className={styles.superDepartmentCards}>
        <SuperDepartmentCard name="Shop Gifts" slug="gifts" moreText="Accessories, Candles, Novelties and more..." />
        <SuperDepartmentCard name="Shop Kids" slug="kids" moreText="Baby, Games, Toys and more... " />
      </Container>

      <SuperDepartmentSection isTinted superDepartment={superDepartments?.find(d => d?.department.slug === 'home')} />
      <SuperDepartmentSection superDepartment={superDepartments?.find(d => d?.department.slug === 'kids')} />
      <SuperDepartmentSection
        isTinted
        superDepartment={superDepartments?.find(d => d?.department.slug === 'kitchen')}
      />
      <SuperDepartmentSection superDepartment={superDepartments?.find(d => d?.department.slug === 'outdoors')} />
      <SuperDepartmentSection
        isTinted
        superDepartment={superDepartments?.find(d => d?.department.slug === 'seasonal')}
      />

      <Container>
        <div ref={repRecommendationsRef}>
          {/* Show only after all of the queries have been fetched */}
          {isRep && !homepageDataQuery.loading && homepageDataQuery.data && (
            <MarketplaceRecommendationsPanel className={styles.repRecommendationsPanel} hideLoading />
          )}
        </div>
      </Container>

      {isGuest && (
        <>
          <MarketingRetailerMapPanel />
          <MarketingSellerTestimonialsPanel />
          <SellerMarketingPanel />
        </>
      )}

      {isBuyer && <BuyerFlowManager />}

      {isRep && <RepHowToGuideModal isOpen={isRep} />}
    </BasePage>
  )
}

export default withProductCategoryPreferencesPage(HomePage)
