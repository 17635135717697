import React from 'react'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import { formattedStats } from 'constants/marketing-stats'

import styles from './MarketingRetailerMapPanel.module.css'

const MarketStatsPanel = () => (
  <section className={styles.mapSection}>
    <Container className={styles.mapSectionContent}>
      <img className={styles.mapImage} src="/marketing/retailer-page/map.png" alt="Map Image" />
      <h2 className={styles.sectionTitle}>Join {formattedStats.buyers}+ retailers across Australia & New Zealand</h2>
      <p className={styles.sectionBodyCopy}>
        Fieldfolio is the No. 1 online wholesale marketplace, with tens of thousands of retailers and store owners
        already on board. From fashion, consumer electronics, sporting goods, beauty products and handmade products,
        Fieldfolio is your one-stop shop.
        <br />
        <br />
        Don&apos;t miss out on Australia and New Zealand&apos;s most exciting array of wholesale products!
      </p>
    </Container>
  </section>
)

export default MarketStatsPanel
